import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MdExplore, MdPerson, MdAddCircleOutline, MdHome } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import firebase from 'firebase'


function BottomNav() {
    const location = useLocation();
    const [authState, setAuthState] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState("Logged-out")
            } else {
                setAuthState("Logged-in")
            }
        });
    }, []);
    if (authState === "Logged-out") {
        return (
            <>
                <Redirect to="/" />
            </>
        )
    } else {
        return (
            <div className='bottom_nav_container'>
                <Link to="/home" style={{ fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/home' || location.pathname === '/create-profile' ? 'white' : '#404040'}`, justifyContent: 'center', alignItems: 'center' }}>
                    <MdHome style={{
                        color: `${location.pathname === '/home' || location.pathname === '/home/property' ? 'white' : '#404040'}`,
                        fontSize: '28px',
                    }} />
                </Link>
                <Link to="/explore" style={{ fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/map-view' || location.pathname === '/create-profile' || location.pathname === '/explore' || location.pathname === '/vacation-villas' || location.pathname === '/family-apartments' || location.pathname === '/personal-rooms' || String(location.pathname).slice(0, 9) === '/property' ? 'white' : '#404040'}`, justifyContent: 'center', alignItems: 'center' }}>
                    <MdExplore style={{
                        color: `${location.pathname === '/map-view' || location.pathname === '/create-profile' || location.pathname === '/explore' || location.pathname === '/vacation-villas' || location.pathname === '/family-apartments' || location.pathname === '/personal-rooms' || String(location.pathname).slice(0, 9) === '/property' ? 'white' : '#404040'}`,
                        fontSize: '28px',
                    }} />
                </Link>
                <Link to="/become-host" style={{ fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/become-host' || location.pathname === '/create-profile' ? 'white' : '#404040'}`, justifyContent: 'center', alignItems: 'center' }}>
                    <MdAddCircleOutline style={{
                        color: `${location.pathname === '/become-host' ? 'white' : '#404040'}`,
                        fontSize: '28px',
                    }} />
                </Link>
                <Link to="/my-profile" style={{ fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/my-profile' || location.pathname === '/create-profile' ? 'white' : '#404040'}`, justifyContent: 'center', alignItems: 'center' }}>
                    <MdPerson style={{
                        color: `${location.pathname === '/my-profile' || location.pathname === '/create-profile' ? 'white' : '#404040'}`,
                        fontSize: '28px',
                    }} />
                </Link>
            </div>
        )
    }
}

export default BottomNav