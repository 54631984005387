import React, { useEffect, useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase'
import { UserContext } from '../context/UserContext'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { database } from "../config";

export default function Signup() {
  const context = useContext(UserContext);

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [signIn, setSignIn] = useState(true)
  var provider = new firebase.auth.GoogleAuthProvider();

  const googleAuth = () => {
    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        // var credential = result.credential;
        // var token = credential.accessToken;
        // The signed-in user info.
        // var Signeduser = result.user;
        toast(`Welcome ${result.user.displayName}`, { type: "success" })
      }).catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        var errorMessage = error.message;
        // var email = error.email;
        // var credential = error.credential;
        toast(errorMessage, { type: "error" });
        // ...
      });
  }
  const emailPasswordAuth = () => {
    if (signIn) {
      firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
        var errorMessage = error.message;
        toast(errorMessage, { type: "error" });
        console.log(error);
      });
    } else if (!signIn) {
      if (password === confirmPassword) {
        firebase.auth().createUserWithEmailAndPassword(email, password).catch((error) => {
          var errorMessage = error.message;
          toast(errorMessage, { type: "error" });
          console.log(error);
        });
      } else {
        alert("passwords didn't match")
      }
    }
  }
  useEffect(() => {
    database.ref("site_visits").child('visits').set({
      visits: firebase.database.ServerValue.increment(1)
    })
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        context.setUser({ email: user.email, uid: user.uid })
      }
    });
  }, [])
  useEffect(() => {
    setEmail("");
    setPassword("")
    setConfirmPassword("")
  }, [signIn])

  if (context.user == null) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#000' }}>
        <ToastContainer />
        {
          signIn ?
            <Col className="d-flex justify-content-center align-items-center" md={8} lg={6} xs={12} style={{ minHeight: '100vh', maxHeight: 'max-content', width: '100vw', backgroundSize: 'cover', backgroundImage: 'url("https://images.adsttc.com/media/images/5ef9/ee16/b357/65b2/6200/0692/medium_jpg/Sugar_House___Architects_49_House_Design_11.jpg?1593437652")' }}>
              <Card style={{ border: 'none', background: '#fff0', color: '#fff', width: '95%' }} >
              <div style={{  padding: '15px', display: `flex`, justifyContent: 'center', width: '100%', marginBottom:'40px', marginTop:'-100px' }}>
                <center>
                  <div>
                    <h2 className="fw-bold mb-2" style={{ color: '#fff' }}>Homeyardz</h2> 
                    <h5 style={{ color: '#fff' }}>Property Listings Showing Off</h5> 
                  </div>
                </center>
              </div>
                <Card.Body >
                  <div className="mb-3 mt-md-4">
                    {/* <img height="150px" width="150px" style={{ marginLeft: '-15px' }} src="https://firebasestorage.googleapis.com/v0/b/real-d1517.appspot.com/o/Black%20and%20White%20Feminine%20Real%20Estate%20Logo%20(3).png?alt=media&token=8359b2a6-ed0e-44f0-a187-6214adf8020f" /> */}
                    <h2 className="fw-bold mb-2 text-uppercase" style={{ color: '#fff' }}>Welcome</h2>
                    <p className=" mb-3">Sign-in to Continue!</p>
                    <div className="mb-3">
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="text-center">
                            Email address
                          </Form.Label>
                          <Form.Control value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" placeholder="Enter email" style={{ height: '46px', padding: '10px 10px', borderRadius: '10px', background: '#fff0', color: '#fff' }} />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="Password" style={{ height: '46px', padding: '10px 10px', borderRadius: '10px', background: '#fff0', color: '#fff' }} />
                        </Form.Group>
                        {/* <Form.Group
                              className="mb-3"
                              controlId="formBasicCheckbox"
                            >
                              <p className="small">
                                <a className="text-primary" href="#!">
                                  Forgot password?
                                </a>
                              </p>
                            </Form.Group> */}
                        <center>
                          <Button variant="primary" onClick={emailPasswordAuth} style={{ width: '100%', padding: '10px 0px', borderRadius: '10px', marginBottom: '10px' }}>
                            Sign In
                          </Button>
                        </center>
                        <center>
                          <Button variant="primary" onClick={googleAuth} style={{ width: '100%', padding: '10px 0px', borderRadius: '10px' }}>
                            Sign In with Google
                          </Button>
                        </center>
                      </Form>
                      <div className="mt-3">
                        <p className="mb-0  text-center">
                          Don't have an account?{" "}
                          <a onClick={() => { setSignIn(false) }} className="text-primary fw-bold">
                            Sign Up
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            :
            <Col className="d-flex justify-content-center align-items-center" md={8} lg={6} xs={12} style={{ minHeight: '100vh', maxHeight: 'max-content', backgroundSize: 'cover', backgroundImage: 'url("https://images.adsttc.com/media/images/5ef9/ee16/b357/65b2/6200/0692/medium_jpg/Sugar_House___Architects_49_House_Design_11.jpg?1593437652")' }}>
              <Card style={{ border: 'none', background: '#fff0', color: '#fff', width: '95%' }} >
                <Card.Body >
                  <div className="mb-3 mt-md-4">
                    <img height="150px" width="150px" style={{ marginLeft: '-15px' }} src="https://firebasestorage.googleapis.com/v0/b/real-d1517.appspot.com/o/Black%20and%20White%20Feminine%20Real%20Estate%20Logo%20(3).png?alt=media&token=8359b2a6-ed0e-44f0-a187-6214adf8020f" />
                    <h2 className="fw-bold mb-2 text-uppercase" style={{ color: '#fff' }}>Create an Account</h2>
                    <p className=" mb-3">Sign-in to get Stareted</p>
                    <div className="mb-3">
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="text-center">
                            Email address
                          </Form.Label>
                          <Form.Control value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" placeholder="Enter email" style={{ height: '46px', padding: '10px 10px', borderRadius: '10px', background: '#fff0', color: '#fff' }} />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="Password" style={{ height: '46px', padding: '10px 10px', borderRadius: '10px', background: '#fff0', color: '#fff' }} />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} type="password" placeholder="Password" style={{ height: '46px', padding: '10px 10px', borderRadius: '10px', background: '#fff0', color: '#fff' }} />
                        </Form.Group>
                        <center>
                          <Button variant="primary" onClick={emailPasswordAuth} style={{ width: '100%', padding: '10px 0px', borderRadius: '10px', marginBottom: '10px' }}>
                            Sign Up
                          </Button>
                        </center>
                        <center>
                          <Button variant="primary" onClick={googleAuth} style={{ width: '100%', padding: '10px 0px', borderRadius: '10px' }}>
                            Sign Up with Google
                          </Button>
                        </center>
                      </Form>
                      <div className="mt-3">
                        <p className="mb-0  text-center" onClick={() => { setSignIn(true) }}>
                          Already have an account?{" "}
                          <a onClick={() => { setSignIn(true) }} className="text-primary fw-bold">
                            Sign In
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
        }
      </div>
    )
  } else {
    return (
      <Redirect to="/home" />
    )

  }

}