import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faBed,
  faBath,
  faCar,
} from "@fortawesome/free-solid-svg-icons";
import { HiHeart, HiOutlineHeart } from 'react-icons/hi'
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase";
import { database } from "../config";

export default function MyListings() {
  const [show, setShow] = useState(false);
  const [deleteKey, setDeleteKey] = useState("")

  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setDeleteKey(key)
    setShow(true);
  }

  //Authstate
  const [authState, setAuthState] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [listingsCheck, setListingsCheck] = useState(null);
  //snapshots
  const [listings, setListings] = useState([]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState(false);
      } else {
        setAuthState(true);
        setUserUid(user.uid);
      }
    });
  }, []);

  //TODO : setLoading status as false
  useEffect(() => {
    database
      .ref("properties")
      .orderByChild("userUid")
      .equalTo(userUid)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          setListingsCheck(true);

        } else {
          setListingsCheck(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userUid]);
  //

  //get listing data
  useEffect(() => {
    database
      .ref("properties")
      .orderByChild("userUid")
      .equalTo(userUid)
      .on("value", (snapshot) => {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childKey = childSnapshot.key;
          var data = childSnapshot.val();
          items.push({
            key: childKey,
            title: data.title,
            imageOneURL: data.imageOneURL,
            bedrooms: data.bedrooms,
            bathrooms: data.bathrooms,
            per_month: data.per_month,
            parkingSpace: data.parkingSpace,
            address: data.address,
            sellingPrice: data.sellingPrice
          });
        });
        setListings(items);
      });
  }, [userUid]);
  //

  return (
    <div>
      {listingsCheck === true ? <h2 className="text-center">My Listings</h2> : ""}

      <div className="featured_card_grid_container" style={{ padding: '0px 20px' }}>
        {listings.map((data, id) => (
          <div className="featured_card_container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '15px', }} key={uuidv4()}>
            <img
              src={data.imageOneURL}
              style={{ borderRadius: '15px 15px 0px 0px', height: '200px', width: '100%' }}
            />
            <div style={{ width: '100%', color: '#000', padding: '15px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ flex: '.8', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>{Number(data.sellingPrice).toLocaleString('en')}</span>
                  <span><FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon> {String(data.address).slice(0, 20)} </span>
                </div>
                <div style={{ flex: '.2', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '25px', maxHeight: '50px', maxWidth: '50px', backgroundColor: '#ff000010' }}>
                  <Button variant="danger" onClick={() => { handleShow(data.key) }}>
                    Delete
                  </Button>
                </div>
              </div>
              <hr style={{ margin: '10px 0px' }} />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', backgroundColor: '#00000020', borderRadius: '10px', padding: '5px 10px' }}>
                  <FontAwesomeIcon icon={faBed} style={{ marginRight: '10px', color: '#000' }} />{data.bedrooms}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', backgroundColor: '#00000020', borderRadius: '10px', padding: '5px 10px' }}>
                  <FontAwesomeIcon icon={faBath} style={{ marginRight: '10px', color: '#000' }} /> {data.bathrooms}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', backgroundColor: '#00000020', borderRadius: '10px', padding: '5px 10px' }}>
                  <FontAwesomeIcon icon={faCar} style={{ marginRight: '10px', color: '#000' }} /> {data.parkingSpace && data.parkingSpace !== "" ? data.parkingSpace : 'None'}
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>DevBud</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    const propertyDB = firebase
                      .database()
                      .ref("properties")
                      .child(deleteKey);
                    propertyDB.remove();
                    handleClose();
                  }}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ))}
      </div>
    </div>
  );
} 