import firebase from 'firebase'
import "firebase/storage"

var firebaseConfig = {
  apiKey: "AIzaSyDIn-RPYVWTeY99r34ia_RAbJRZioMfh0A",
  authDomain: "real-d1517.firebaseapp.com",
  projectId: "real-d1517",
  storageBucket: "real-d1517.appspot.com",
  messagingSenderId: "1076807209057",
  appId: "1:1076807209057:web:751f1906df8d9f64e25a20"

  // apiKey: "AIzaSyC1LeQdU8AzizYpbRaqRKnZywc3sXRskBU",
  // authDomain: "homevid-ecebc.firebaseapp.com",
  // databaseURL: "https://homevid-ecebc-default-rtdb.firebaseio.com",
  // projectId: "homevid-ecebc",
  // storageBucket: "homevid-ecebc.appspot.com",
  // messagingSenderId: "367534401031",
  // appId: "1:367534401031:web:3ae26d4d97513cc1292ca2",
  // measurementId: "G-KH6C2MEGBT"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var database = firebase.database();

const auth = firebase.auth();

export const storage = firebase.storage();

export { auth };

export { database };
