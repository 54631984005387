import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { database } from "../config";

export default function CategoriesSection() {
  const [categories, setCategories] = useState([])
  const [locations, setLocations] = useState([])
  useEffect(() => {
    database.ref("categories").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          items.push(childData);
        });
        setCategories(items)
      }
    }).catch((error) => {
      console.error(error);
    });
    database.ref("locations").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          items.push(childData);
        });
        setLocations(items)
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [])

  return (
    <div>
      <div>
        <p style={{ color: 'black', fontSize: '1.1em', fontWeight: '600', margin: '10px 0px' }}>Categories</p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100vw - 20px)',
          overflow: 'scroll',
          paddingRight: '10px'
        }}>
          {
            categories.map((catagory) => {
              return (
                <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0' }}>
                  <Link to={{ pathname: `${catagory.linkTo}`, state: { fromDashboard: true, category: catagory.name } }}>
                    <Card className="category-cards" >
                      <Card.Img variant="top" src={catagory.url} className="category-img" style={{ height: '100%', width: '100%', borderRadius: '10px' }} />
                      <Card.Body style={{ position: 'absolute', bottom: '0', width: '100%', backgroundImage: 'linear-gradient(360deg, black, transparent)', borderRadius: '10px' }}>
                        <p style={{ fontWeight: 'normal', margin: '0', color: 'white', letterSpacing: '2px' }}>{catagory.name}</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )
            })
          }
        </div>
        <p style={{ color: 'black', fontSize: '1.1em', fontWeight: '600', margin: '10px 0px' }}>Top Locations</p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100vw - 20px)',
          overflow: 'scroll',
          paddingRight: '10px'
        }}>
          {
            locations.map((location) => {
              return (
                <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0' }}>
                  <Link to={{ pathname: `${location.linkTo}`, state: { fromDashboard: true, lng: location.coordinates.lng, lat: location.coordinates.lat } }}>
                    <Card className="category-cards" >
                      <Card.Img variant="top" src={location.imgURL} className="category-img" style={{ height: '100%', width: '100%', borderRadius: '10px' }} />
                      <Card.Body style={{ position: 'absolute', bottom: '0', width: '100%', backgroundImage: 'linear-gradient(360deg, black, transparent)', borderRadius: '10px' }}>
                        <p style={{ fontWeight: 'normal', margin: '0', color: 'white', letterSpacing: '2px' }}>{location.title}</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}
